<template>
  <div class="filters">
    <v-container fluid>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="3">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                label="Selecionar intervalo de datas"
                prepend-icon="event"
                readonly
                v-on="on"
                class="input-datas"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col class="d-flex" cols="12" sm="4">
          <v-select
            :items="produtos"
            item-text="name"
            item-value="id"
            v-model="produto"
            label="Produto"
            dense
          ></v-select>
        </v-col>

        <v-col class="d-flex" cols="12" sm="4">
          <v-select
            :items="resultados"
            item-text="name"
            item-value="id"
            v-model="resultado"
            label="Resultado Final"
            dense
            multiple
          ></v-select>
        </v-col>

        <v-col class="d-flex" cols="12" sm="1">
          <v-spacer></v-spacer>
          <v-btn dark @click="generateReport()"> Gerar </v-btn>
        </v-col>
      </v-row>
      <OverlayAlert @confirm="confirmClose()" />
    </v-container>
  </div>
</template>

<script>
import OverlayAlert from "@/components/OverlayAlert";

export default {
  name: "FiltersExport",
  components: { OverlayAlert },
  data: () => ({
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    produtos: [],
    results: [],
    date: [],
    menu: false,
    modal: false,
    menu2: false,
    produto: "",
    resultado: [],
  }),
  computed: {
    dateRangeText() {
      return this.date.join(" a ") || "";
    },
    resultados() {
      if (this.produto == "" || this.produto == null) {
        return [];
      } else {
        let res = this.results.filter((e) => e.process_id == this.produto);

        return res;
      }
    },
    dateInt() {
      let res = this.date;
      res = res.sort((a, b) => {
        var c = new Date(a);
        var d = new Date(b);
        return c - d;
      });

      return res;
    },
  },
  mounted() {
    this.collectPageData();
  },
  methods: {
    async collectPageData() {
      await this.$store.dispatch("products");
      this.produtos = this.$store.state.leadProducts;

      await this.$store.dispatch("process_results");

      this.results = this.$store.state.leadProcessResults;
    },
    async generateReport() {
      let filters = {
        start_date: this.dateInt[0] || null,
        end_date: this.dateInt[1] || null,
        produto: this.produto || null,
        resultados: this.resultado || null,
      };

      let validFilters = true;
      let last = true;

      while (validFilters) {
        for (let i in filters) {
          if (
            filters[i] == null ||
            (i == "resultados" && filters[i].length == 0)
          ) {
            this.$store.commit("SET_OVERLAY_DATA", {
              overlay: true,
              message: "Todos os filtros são necessários",
              cancel: false
            });

            validFilters = false;
            last = false;
          }

          if (i == filters.length - 1) {
            validFilters = false;
          }
        }
      }

      if (last) {
        this.$emit("collect-leads", filters);
      }
    },
    confirmClose: async function () {
      this.$store.commit("SET_OVERLAY_DATA", {
        overlay: false, 
        message: "", 
        cancel: false 
      });
    },
  },
};
</script>

<style scoped>
.filters {
  margin: 20px 40px 0px 296px;
}

.input-datas {
  margin-top: -2px;
  font-size: 14px;
}
</style>