
<template>
  <div class="reports">
    <h1>
      Export Leads
      <span class="h1-underline"></span>
    </h1>
    <FiltersExport @collect-leads="collectLeads" />
    <v-card>
      <v-card-title class>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="dados"
        :search="search"
        :loading="loading"
        loading-text="A carregar..."
      ></v-data-table>
    </v-card>
    <v-btn
      :loading="loading5"
      :disabled="loading5"
      color="blue-grey"
      class="ma-2 white--text"
      fab
      @click="export_excel(dados)"
    >
      <v-icon dark>mdi-cloud-download</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { json2excel } from "js2excel";
import FiltersExport from "@/components/Filters_Export";
export default {
  components: {
    FiltersExport,
  },
  name: "Export",
  data() {
    return {
      search: "",
      headers: [
        {
          text: "ID",
          align: "left",
          value: "lead_process_id",
        },
        {
          text: "Data Registos",
          align: "center",
          value: "dt_created",
          sortable: true,
        },
        {
          text: "Mediador",
          align: "center",
          value: "username",
          sortable: true,
        },
        { text: "Nome", align: "center", value: "nome", sortable: true },
        { text: "Email", align: "center", value: "email", sortable: true },
        {
          text: "Telefone",
          align: "center",
          value: "telefone",
          sortable: true,
        },
        { text: "Montante", align: "center", value: "aux_1", sortable: true },
        {
          text: "Último Contacto",
          align: "center",
          value: "last_interation",
          sortable: true,
        },
        {
          text: "Estado",
          align: "center",
          value: "current_step",
          sortable: true,
        },
      ],

      dados: [],
      loader: null,
      loading: false,
      loading2: false,
      loading3: false,
      loading4: false,
      loading5: false,

      startDT: "",
      endDT: "",
    };
  },
  methods: {
    export_excel: async function (data) {
      let app = this;
      try {
        json2excel({
          data,
          name: `leads_${app.startDT}_a_${app.endDT}`,
          formateDate: "yyyy-mm-dd",
        });
      } catch (e) {
        alert("export error: " + e);
      }
    },
    async collectLeads(filters) {
      this.loading = true;

      this.startDT = filters.start_date.toString().slice(0, 10);
      this.endDT = filters.end_date.toString().slice(0, 10);

      // let url = "https://app.reorganiza.pt/public/api/lead-report"
      // const leads = await this.call_api_post(url, filters)

      await this.$store.dispatch("collect_leads_filters", filters);

      this.dados = this.$store.state.leadsFilters;

      this.loading = false;
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
};
</script>

<style scoped>
h1 {
  margin-left: 296px;
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 50px;
  position: relative;
  display: block;
}
.h1-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background-color: black;
  margin-bottom: -6px;
}
.v-card {
  margin: 10px 40px 50px 296px;
  padding: 40px;
  padding-top: 10px;
}
.v-card__title {
  margin-bottom: 50px;
}

.v-btn {
  margin-left: 330px !important;
  margin-top: -120px !important;
  width: 70px;
  height: 70px;
  background-color: rgb(246, 147, 30) !important;
}
.v-btn i {
  font-size: 30px !important;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>>
   